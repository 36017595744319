<template>
   <div class="bg">
      <div class="left-box">
         <div class="logo">
            <img src="../../assets/logo2.png" alt="">
         </div>
         <!-- <div class="twitter"></div> -->
      </div>
      <div class="main">
         <div class="services">
            <div>
               E-Mail：666manageworld@gmail.com
            </div>
         </div>
         <div class="outlook">
            <div style="margin-top: 30px;">
               WhatsApp：
            </div>
            <div>
               <div style="margin-top: 30px;">
                  <img src="../../assets/wahtsapp1.png" alt="">
               </div>
               <div class="auto">
                  客服1
               </div>
            </div>
            <div>
               <div style="margin:30px 0 0 30px">
                  <img src="../../assets/wahtsapp2.png" alt="">
               </div>
               <div class="auto">
                  客服2
               </div>
            </div>
         </div>
         <!-- <div class="services">
            <h4>Services</h4>
            <ul>
               <li v-for="item in servicesList" :key="item.id">
                  > <span style="padding-left: 5px;">{{ item.title }}</span>
               </li>
            </ul>
         </div>
         <div class="outlook">
            <h4>Outlook</h4>
            <ul>
               <li v-for="item in outlookList" :key="item.id">
                  > <span style="padding-left: 5px;">{{ item.title }}</span>
               </li>
            </ul>
         </div> -->
      </div>
      <!-- <div class="right-box">
         <h4 class="title">Subscribe</h4>
         <div class="description">Get to kone about Ocean Crown, our updates and all news, straight to your inbox.</div>
         <div style="margin-top: 25px;display: flex;">
            <input type="text" placeholder="Your Email ID">
            <button>></button>
         </div>
      </div> -->
      <div class="right-box">
         <div>
            Telegram：eth63456 &emsp; Mirabel w
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "Footer",
   data() {
      return {
         // servicesList: [
         //    {
         //       id: 1,
         //       title: "Project Cargo"
         //    },
         //    {
         //       id: 2,
         //       title: "Shipping Agency"
         //    },
         //    {
         //       id: 3,
         //       title: "Used Container"
         //    },
         //    {
         //       id: 4,
         //       title: "Air Freight"
         //    },
         //    {
         //       id: 5,
         //       title: "Lang Freight"
         //    },
         //    {
         //       id: 6,
         //       title: "Sea Freight"
         //    },
         // ],
         // outlookList: [
         //    {
         //       id: 1,
         //       title: "Shipment Tracking"
         //    },
         //    {
         //       id: 2,
         //       title: "Get A Quote"
         //    },
         //    {
         //       id: 3,
         //       title: "Offers Zone"
         //    },
         //    {
         //       id: 4,
         //       title: "Our Associates"
         //    },
         //    {
         //       id: 5,
         //       title: "News & Events"
         //    },
         //    {
         //       id: 6,
         //       title: "Careers"
         //    },
         // ],
      }
   }
}
</script>

<style scoped>
li {
   list-style-type: none;
}
h4{
   color: #fff;
}
.auto{
  text-align: center;
}
.bg {
   width: 100%;
   height: 330px;
   /* background-image: url(../../assets/footer.png); */
   /* background-repeat: no-repeat; */
   /* background-size: 100% 518px; */
   background-color: #041329;
   color: #fff;
   box-sizing: border-box;
   overflow: hidden;
   display: flex;
   align-items: center;
   justify-content: space-evenly
}
.logo{
   width: 266px;
    height: 94px;
    padding-top: 61px;
    margin: 0 auto;
}
.logo>img{
   width: 100%;
   height: 100%;
}

.left-box {
   width: 50%;
   height: 100%;
   /* background-color: rgb(98, 134, 166); */
}

.logo {
   font-weight: 500;
   font-size: 45px;

}

.text {
   margin: 50px 0 30px;
   line-height: 25px;
}

.twitter {
   width: 182px;
   height: 97px;
   background-image: url(../../assets/footer.png);
   background-position: -373px -328px;
   background-repeat: no-repeat;
}

.main {
   width: 23%;
   /* display: flex; */
}

.services,
.outlook {
   width: 100%;
}
.outlook {
   display: flex;
}
.services{
   height: 25px;
}

.services>ul,
.outlook>ul,
.right-box>.description {
   margin-top: 20px;
}

.description {
   line-height: 44px;
}

.services>ul>li,
.outlook>ul>li {
   line-height: 45px;
}

.right-box {
   width: 30%;
   margin-bottom: 160px;
   /* background-color: rgb(94, 163, 224); */
}

.right-box>div>input {
   width: 300px;
   height: 48px;
   padding-left: 25px;
   box-sizing: border-box;
}

.right-box>div>input:focus {
   outline: none;
}

.right-box>div>button {
   height: 48px;
   width: 75px;
   border: none;
   background-color: #0078ff;
   color: #fff;
   font-size: 20px;
}
</style>