<template>
    <div>
       <div class="case-title">
        Some cases show
       </div>
       <div style="width: 1367px;margin: auto;overflow: hidden;">
          <div class="swiper-container1">
             <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in imgList" @click="jump(item)">
                   <div class="bg">
                      <div class="title">{{ item.title }}</div>
                      <div class="city">Country：{{ item.city }}</div>
                      <img :src="item.imgUrl" alt="">
                   </div>
                </div>
             </div>
             <div class="swiper-button-prev rot" slot="button-prev">
                <img class="swiper-button-img" src="../../assets/left.png" />
             </div>
             <div class="swiper-button-next rat" slot="button-next">
                <img class="swiper-button-img" src="../../assets/right.png" />
             </div>
          </div>
       </div>
    </div>
 </template>
 
 <script>
 import Swiper from 'swiper';
 import 'swiper/css/swiper.min.css'
 import img1 from "@/assets/221.png"
 import img2 from "@/assets/333.png"
 import img3 from "@/assets/123(1).png"
 import img4 from "@/assets/324.png"
 import img5 from "@/assets/55.png"
 export default {
    name: "Case",
    data() {
       return {
          initialNumber: 0,
          imgList: [
             {
                id: 1,
                imgUrl: img1,
                title: "Gull Network",
                city: "Malaysia",
             },
             {
                id: 2,
                imgUrl: img2,
                title: "SoulLand",
                city: "Korea/Thailand"
             },
             {
                id: 3,
                imgUrl: img3,
                title: "Fire Coin",
                city: "Malaysia"
             },
             {
                id: 4,
                imgUrl: img4,
                title: "Tiktok shop",
                city: "Korea/Thailand"
             },
             {
                id: 5,
                imgUrl: img5,
                title: "Stock",
                city: "Korea/Thailand"
             }
          ],
       }
    },
    watch: {
 
    },
    methods: {
       initSwiper() {
          new Swiper('.swiper-container1', {
             effect: 'coverflow',
             grabCursor: true,
             centeredSlides: true,
             slidesPerView: 'auto',
             spaceBetween: 30,
             initialSlide: 2,
             coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 0.8,
                slideShadows: true,
                scale: 0.8
             },
             loop: true,
             navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
             },
             // autoplay: {
             //    delay: 3000,
             // },
          })
          document.querySelectorAll('.swiper-slide-shadow-left').forEach(item => {
             item.style = 'background-image:none;'
          })
          document.querySelectorAll('.swiper-slide-shadow-right').forEach(item => {
             item.style = 'background-image:none;'
          })
       },
       jump(item) {
          console.log(item)
          this.$router.push({
             path:'/accountNumberShow_en',
             query: {
                title: item.title,
                city: item.city
             }
          })
       }
    },
    mounted() {
       this.initSwiper()
    }
 }
 </script>
 
 <style scoped>
 .case-title {
    width: 308px;
    height: 50px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 36px;
    color: #30334B;
    line-height: 50px;
    text-align: left;
    font-style: normal;
    margin: 80px auto 0;
 }
 
 .swiper-container1 {
    width: 100%;
    margin: 44px auto 80px;
 }
 
 .swiper-wrapper {
    width: 90%;
 }
 
 .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 270px;
    height: 417px;
 }
 
 .swiper-container-3d .swiper-slide-shadow-left {
    background-image: #fff !important;
 }
 
 .swiper-container-3d .swiper-slide-shadow-right {
    background-image: #fff !important;
 }
 
 .bg {
    background-repeat: no-repeat;
    background-size: 270px 417px;
    position: relative;
 
 }
 
 .bg .title {
    width: 100px;
    height: 38px;
    position: absolute;
    top: -4px;
    left: -2px;
    background-image: url('../../assets/Corner1.png');
    background-size: 100px 38px;
    text-align: center;
    line-height: 38px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #0B6FF9;
    font-style: normal;
    text-align: center;
 }
 
 .bg .city {
    width: 100%;
    position: absolute;
    bottom: 20px;
    color: #fff;
    text-align: center;
 }
 
 .bg>img {
    width: 100%;
    height: 100%;
 }
 
 .swiper-button-prev:after,
 .swiper-container-rtl .swiper-button-next:after,
 .swiper-button-next:after,
 .swiper-container-rtl .swiper-button-prev:after {
    content: none;
 }
 
 .swiper-button-prev,
 .swiper-container-rtl .swiper-button-next {
    left: 35px;
    right: auto;
    z-index: 9999;
 }
 
 .swiper-button-next,
 .swiper-container-rtl .swiper-button-prev {
    right: 35px;
    left: auto;
    z-index: 9999;
 }
 </style>