<template>
   <div>
      <div class="swiper-container">
         <div class="header">
            <header-nav></header-nav>
         </div>
         <div class="swiper-wrapper">
            <div class="swiper-slide swiper-slide1">
               <img style="width: 100%;height: 100%;" src="../../assets/banner.png" alt="">
            </div>
            <div class="swiper-slide swiper-slide2">
               <img style="width: 100%;height: 100%;" src="../../assets/banner.png" alt="">
            </div>
            <div class="swiper-slide swiper-slide3">
               <img style="width: 100%;height: 100%;" src="../../assets/banner.png" alt="">
            </div>
            <div class="swiper-slide swiper-slide4">
               <img style="width: 100%;height: 100%;" src="../../assets/banner.png" alt="">
            </div>
         </div>
         <div class="swiper-button-prev rot" slot="button-prev">
            <img class="swiper-button-img" src="../../assets/left.png" />
         </div>
         <div class="swiper-button-next rat" slot="button-next">
            <img class="swiper-button-img" src="../../assets/right.png" />
         </div>
         <div class="swiper-pagination"></div>
      </div>
      <div class="box">
         <div class="box-detail" v-for="item in listOne" :key="item.id">
            <div class="img">
               <img :src="item.imgUrl" alt="">
            </div>
            <div class="title">
               <span>{{ item.title }}</span>
            </div>
            <div class="subheading">
               <span>{{ item.subheading }}</span>
            </div>
            <div class="detail">
               <span>{{ item.detail }}</span>
            </div>
         </div>
      </div>
      <div class="box1">
         <div class="box-detail" v-for="item in listTwo" :key="item.id">
            <div class="img">
               <img :src="item.imgUrl" alt="">
            </div>
            <div class="title">
               <span>{{ item.title }}</span>
            </div>
            <div class="subheading">
               <span>{{ item.subheading }}</span>
            </div>
            <div class="detail">
               <span>{{ item.detail }}</span>
            </div>
         </div>
      </div>
      <div>
         <CaseComponent></CaseComponent>
      </div>
      <div>
         <EnterpriseDynamicsComponent></EnterpriseDynamicsComponent>
      </div>
      <div>
         <ContactUs></ContactUs>
      </div>
      <div>
         <footer-bottom></footer-bottom>
      </div>
      <tool-bar></tool-bar>
   </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css'
import CaseComponent from "@/components/Case/index.vue"
import EnterpriseDynamicsComponent from "@/components/EnterpriseDynamics/index.vue"
import ContactUs from "@/components/ContactUs/index.vue"
import img1 from '@/assets/icon1.png'
import img2 from '@/assets/icon2.png'
import img3 from '@/assets/icon3.png'
import img4 from '@/assets/icon4.png'
import img5 from '@/assets/icon5.png'
import img6 from '@/assets/icon6.png'

export default {
   name: "Home",
   components: {
      CaseComponent,
      EnterpriseDynamicsComponent,
      ContactUs,
   },
   data() {
      return {
         listOne: [
            {
               id: " 001",
               imgUrl: img1,
               title: "全球首家數字媒體投放平台",
               subheading: "Web3xGPT-AI—去中心化x人工智慧",
               detail: "我們擁有全球最頂尖的技術團隊，打造了全球最大的Tiktok矩陣系統，目前已覆蓋全球21個國家和地區，並維持持續成長。"
            },
            {
               id: " 002",
               imgUrl: img2,
               title: "7年+的行業深耕",
               subheading: "我們有卓越的行銷策劃團隊，豐富的產業資源。",
               detail: "我們擁有頂尖的營運團隊和豐富的產業資源，透過強大的矩陣系統，依托AI智慧技術為您的事業提供專業的行銷服務。"
            },
            {
               id: " 003",
               imgUrl: img3,
               title: "企業服務",
               subheading: "AI數字技術領先性",
               detail: "我們基於AIGC技術創建內容生成數位人，透過AI矩陣主播為商家在Tiktok上發布，協助您的品牌進行國際推廣。"
            },
         ],
         listTwo: [
            {
               id: " 001",
               imgUrl: img4,
               title: "我們的優勢",
               subheading: "全球範圍的上百個合作品牌",
               detail: "我們在21個國家和地區建立了超10萬➕Tiktok帳戶，建構了全球最大矩陣系統，選擇香港風雲集團，意味著選擇了一個能夠提供卓越導流效果和高轉換率的合作夥伴。"
            },
            {
               id: " 002",
               imgUrl: img5,
               title: "市場前景",
               subheading: "連續3年增長顯著,TikTok孕育勃勃新機",
               detail: "2023年全球數位行銷支出7,000億美元，人工智慧是數位行銷未來發展的技術基礎，2024年美國在Tiktok上的行銷業務仍將成長36%，Tiktok上的用戶數量正在不斷增加，為目前全球最大的流量池。"
            },
            {
               id: " 003",
               imgUrl: img6,
               title: "Tiktok+AIGC",
               subheading: "将是人工智能市場的下一個風口",
               detail: "Tiktok擁有全球龐大的用戶群體，提供大量的潛在受眾，平台透過演算法分析用戶行為和興趣，利用AI技術使行銷推播變得更加智慧和精準，幫助您的品牌更有效的傳達訊息。"
            },
         ]
      }
   },
   mounted() {
      new Swiper('.swiper-container', {
         autoplay: {
            delay: 3000,
         },
         loop: true,
         pagination: {
            el: '.swiper-pagination',
         },
         navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
         },
      })
   }

}
</script>

<style scoped>
.header {
   position: absolute;
   left: 0;
   z-index: 9999;
   width: 100%;
}

.swiper-container {
   height: 750px;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
   content: none;
}

.swiper-button-img {
   width: 45px;
   height: 45px;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
   left: 58px;
   right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
   right: 58px;
   left: auto;
}

.box {
   width: 1200px;
   margin: 80px auto 0;
   display: flex;
   justify-content: space-evenly;
}

.box1 {
   width: 1200px;
   margin: 30px auto 0;
   display: flex;
   justify-content: space-evenly;
}


.box-detail {
   width: 380px;
   height: 443px;
   background: #F6FAFF;
   border-radius: 24px;
}

.box-detail .img {
   width: 150px;
   height: 150px;
   border: 1px #888888 dashed;
   padding: 3px 10px 6px;
   box-sizing: border-box;
   margin: 31px auto 0;
}

.box-detail .img>img {
   width: 100%;
   height: 100%;
}

.box-detail .title {
   height: 33px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 24px;
   color: #333333;
   line-height: 33px;
   text-align: center;
   font-style: normal;
   margin: 26px auto 0;
}

.box-detail .subheading {
   height: 22px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 16px;
   color: #777777;
   line-height: 22px;
   text-align: center;
   font-style: normal;
   margin: 10px auto 0;
}

.box-detail .detail {
   width: 334px;
   height: 96px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 16px;
   color: #777777;
   line-height: 22px;
   text-align: center;
   font-style: normal;
   margin: 20px auto 0;
}
</style>