<template>
   <div class="bg">
      <div class="info">Super AI digital human incubation platform, full hosting operation 
lets you have no worries
      </div>
      <div class="button">
         Contact us
      </div>
   </div>
</template>

<script>
export default {
   name: "ContactUs",
   data() {
      return {}
   }
}
</script>

<style scoped>
.bg {
   width: 100%;
   height: 310px;
   background-image: url(../../assets/bg.png);
   background-repeat: no-repeat;
   background-size: 100% 310px;
   overflow: hidden;
}

.bg .info {
   width: 1200px;
   height: 72px;
   font-family: LiHeiPro;
   font-size: 36px;
   color: #F3FDFF;
   text-shadow: 0px 4px 1px #0B6FF9;
   text-align: center;
   font-style: normal;
   margin: 0 auto;
   margin-top: 78px;
}

.bg .button {
   
   width: 150px;
   height: 40px;
   border-radius: 6px;
   border: 2px solid #FFFFFF;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 18px;
   color: #FFFFFF;
   line-height: 40px;
   text-align: center;
   font-style: normal;
   margin: 60px auto 0;
   cursor: pointer;
}
</style>