<template>
   <div class="tool-bar">
      <div class="box" @click="bottoms">
         <div class="icon icon-kefu"></div>
         <div class="desi">客服</div>
      </div>
      <div class="rectangle"></div>
      <div class="box" @click="handleToTop">
         <div class="icon icon-zhding"></div>
         <div class="desi">置頂</div>
      </div>
   </div>
</template>

<script>
export default {
   name: "ToolBar",
   data() {
      return {

      }
   },
   methods: {
      handleToTop() {
         let top = document.documentElement.scrollTop || document.body.scrollTop
         const timeTop = setInterval(() => {
            document.body.scrollTop = document.documentElement.scrollTop = top -= 50
            if (top <= 0) {
               clearInterval(timeTop)
            }
         }, 10)
      },
      bottoms(){
         window.scrollTo(0, document.documentElement.scrollHeight-document.documentElement.clientHeight);
      }
   },
}
</script>

<style scoped>
.tool-bar {
   width: 68px;
   height: 136px;
   background: rgba(11, 111, 249, 0.2);
   box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
   position: fixed;
   top: 50%;
   right: 0;
   transform: translateY(-50%);
   z-index: 999;
   border-radius: 12px;
}

.box {
   cursor: pointer;
}

.icon {
   width: 29px;
   height: 29px;
   background-repeat: no-repeat;
   background-size: 29px 29px;
   margin: 10px auto 0;
}

.icon-kefu {
   background-image: url(../../assets/service.png);
}

.icon-zhding {
   background-image: url(../../assets/Pinned.png);
}

.desi {
   height: 17px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 12px;
   color: #0B6FF9;
   line-height: 17px;
   text-align: center;
   font-style: normal;
   margin: 0 auto;
}

.rectangle {
   width: 60px;
   height: 1px;
   background: #FFFFFF;
   border-radius: 3px;
   margin: 10px auto 0;
}
</style>