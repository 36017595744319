<template>
   <div>
      <div class="header">
         <header-nav></header-nav>
      </div>
      <div class="main">
         <div class="crumbs-nav">
            <crumbs-nav>
               <template #home>
                  <div class="home">
                     首頁
                  </div>
               </template>
               <template #current>
                  <div class="current">
                     賬號展示
                  </div>
               </template>
            </crumbs-nav>
         </div>
         <div class="price-title">{{$route.query.title}}（{{$route.query.city}}）</div>
         <div class="show-number">部分賬號展示</div>
         <!-- <div class="container">
            <div class="show-box" v-for="item in 8">
               <div class="show-box-button">点击查看</div>
            </div>
            <div class="prev"></div>
            <div class="next"></div>
         </div> -->
         <div style="position: relative;">
            <div class="swiper-container">
               <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="item in 4">
                     <div>
                        <div class="swiper-slide-item"><div class="show-box-button">點擊查看</div></div>
                        <div class="swiper-slide-item"><div class="show-box-button">點擊查看</div></div>
                     </div>
                     <div>
                        <div class="swiper-slide-item"><div class="show-box-button">點擊查看</div></div>
                        <div class="swiper-slide-item"><div class="show-box-button">點擊查看</div></div>
                     </div>
                     <div>
                        <div class="swiper-slide-item"><div class="show-box-button">點擊查看</div></div>
                        <div class="swiper-slide-item"><div class="show-box-button">點擊查看</div></div>
                     </div>
                     <div>
                        <div class="swiper-slide-item"><div class="show-box-button">點擊查看</div></div>
                        <div class="swiper-slide-item"><div class="show-box-button">點擊查看</div></div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="swiper-button-prev rot" slot="button-prev">
               <img class="swiper-button-img" src="../../assets/left.png" />
            </div>
            <div class="swiper-button-next rat" slot="button-next">
               <img class="swiper-button-img" src="../../assets/right.png" />
            </div>
         </div>
      </div>
      <div class="footer">
         <footer-bottom></footer-bottom>
      </div>
      <tool-bar></tool-bar>
   </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css'
export default {
   name: "Price",
   components: {

   },
   data() {
      return {

      }
   },
   methods: {

   },
   mounted() {
      new Swiper('.swiper-container', {
         // autoplay: {
         //    delay: 3000,
         // },
         direction: 'horizontal',
         loop: true,
         pagination: {
            el: '.swiper-pagination',
         },
         navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
         },
         coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 0.8,
            slideShadows: true,
            scale: 0.5
         },
      })
      console.log(this.$route.query)
   },
}
</script>

<style scoped>
.header {
   background-color: #ffff;
}

.main {
   width: 100%;
   background-image: url('../../assets/backdrop-bg.png');
   background-repeat: no-repeat;
   background-size: 100%;
   overflow: hidden;
   padding-bottom: 70px;
}

.home {
   width: 36px;
   height: 25px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 18px;
   color: #9a9ea4;
   line-height: 25px;
   text-align: center;
   font-style: normal;
}

.current {
   width: 72px;
   height: 25px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 18px;
   color: #333333;
   line-height: 25px;
   text-align: center;
   font-style: normal;
}

.price-title {
   height: 59px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 42px;
   color: #333333;
   line-height: 59px;
   text-align: center;
   font-style: normal;
}

.show-number {
   height: 45px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 32px;
   color: #0B6FF9;
   line-height: 45px;
   text-align: center;
   font-style: normal;
   margin-top: 12px;
}

.show-box-button {
   width: 164px;
   height: 32px;
   background: url('../../assets/Button.png') no-repeat;
   background-size: 164px 32px;
   box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
   border-radius: 38px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 14px;
   color: #0B6FF9;
   line-height: 32px;
   text-align: center;
   font-style: normal;
   cursor: pointer;
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
   bottom: 46px;
}

.swiper-container {
   width: 1200px;
   margin: auto;
}

.swiper-slide{
   display: flex;
   justify-content: space-evenly;
}

.swiper-slide-item {
   width: 267px;
   height: 548px;
   background-image: url('../../assets/product.png');
   background-repeat: no-repeat;
   background-size: 267px 548px;
   margin-top: 54px;
   position: relative;
}
.swiper-button-prev{
   left: 80px;
}
.swiper-button-next{
   right: 80px;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
   content: none;
}
</style>