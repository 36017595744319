<template>
    <div>
        <div class="header">
            <header-nav></header-nav>
        </div>
        <div class="main">
            <div>
                <img style="width: 100%;height: 400px;" src="../../assets/about1.png" alt="">
            </div>
            <div class="about-area pt-90">
                <div class="container">
                    <div class="area-title center-title">
                        <div class="aboutUs">About Us</div>
                        <h2 class="aboutTitle">關于我們</h2>
                        <div class="content">
                            <div class="text left animate__animated animate__backInLeft">
                                <p>
                                    &emsp;&emsp; 香港風雲集團是一家專注於AI數位科技成果轉換與落地應用的科技公司。我們以「讓生活數位化，數位生活化」為使命，透過不斷創新的AI智慧技術，實現了卓越的Tiktok國際業務推廣，助力品牌在數位時代取得成功。
                                </p>
                                <p>
                                    &emsp;&emsp;讓我們攜手共進，共同迎接數位未來。香港風雲集團將持續推動AI數位科技的創新應用，為您的事業成長注入新的活力，共同開創一個更智慧和互動的數位世界。
                                </p>
                            </div>
                            <div class="img right animate__animated animate__backInRight">
                                <img src="../../assets/about2.png" />
                                <div class="imgbro"></div>
                            </div>
                        </div>
                    </div>
                    <div style="margin: 100px 0 100px 0;" class="col-md-4 mb-30">
                        <div class="featuresBox">
                            <div class="featuresBox-img">
                                <img src="../../assets/aboutFutuer3.png" alt="">
                            </div>
                            <div class="featuresBox-title">
                                <span>我們的願景</span>
                            </div>
                            <div class="featuresBox-text">
                                <span>助力全球品牌在數位時代取得成功</span>
                            </div>
                        </div>
                    </div>
                    <div style="margin: 100px 0 100px 0;" class="col-md-4 mb-30">
                        <div class="featuresBox">
                            <div class="featuresBox-img">
                                <img src="../../assets/aboutFutuer2.png" alt="">
                            </div>
                            <div class="featuresBox-title">
                                <span>我們的使命</span>
                            </div>
                            <div class="featuresBox-text">
                                <span>讓生活數字化，數字生活化</span>
                            </div>
                        </div>
                    </div>
                    <div style="margin: 100px 0 100px 0;" class="col-md-4 mb-30">
                        <div class="featuresBox">
                            <div class="featuresBox-img">
                                <img src="../../assets/aboutFutuer1.png" alt="">
                            </div>
                            <div class="featuresBox-title">
                                <span>企業文化價值觀</span>
                            </div>
                            <div class="featuresBox-text">
                                <span>創新引領，數字驅動</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <footer-bottom></footer-bottom>
        </div>
        <tool-bar></tool-bar>
    </div>
</template>

<script>
import OrderDialog from "@/components/OrderDialog/index.vue"
export default {
    name: "Price",
    components: {
        OrderDialog
    },
    data() {
        return {}
    },
    methods: {

    }
}
</script>
<style scoped>
@import '../../css/about.css';
@import '../../css/font-awesome.min.css';
@import '../../css/ionicons.min.css';
@import '../../css/animate.css';
@import '../../css/magnific-popup.css';
@import '../../css/meanmenu.css';
@import '../../css/global.css';
@import '../../css/style.css';
@import '../../css/animate.min.css';

.header {
    background-color: #ffff;
}
.featuresBox {
    width: 360px;
    height: 184px;
    background: #F6FAFF;
    border-radius: 24px;
}

.featuresBox-img {
    position: absolute;
    bottom: 124px;
    left: 145px;
    width: 90px;
    height: 90px;
}

.featuresBox-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 33px;
    text-align: center;
    font-style: normal;
    padding-top: 90px;
}

.featuresBox-text {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #777777;
    line-height: 22px;
    text-align: center;
    font-style: normal;
    margin-top: 20px;
   
}
</style>
<style scoped src="../../css/bootstrap.min.css"></style>