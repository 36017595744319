import Vue from 'vue'
import router from '@/router/index.js'
import ToolBar from "@/components/ToolBar/index.vue"
import HeaderComponent from "@/components/Header/index.vue"
import FooterComponent from "@/components/Footer/index.vue"
import CrumbsComponent from "@/components/Crumbs/index.vue"
import ToolBaren from "@/components/ToolBar/en.vue"
import HeaderComponenten from "@/components/Header/en.vue"
import FooterComponenten from "@/components/Footer/en.vue"
import CrumbsComponenten from "@/components/Crumbs/en.vue"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.component('tool-bar', ToolBar)
Vue.component('header-nav', HeaderComponent)
Vue.component('footer-bottom', FooterComponent)
Vue.component('crumbs-nav', CrumbsComponent)
Vue.component('tool-baren', ToolBaren)
Vue.component('header-naven', HeaderComponenten)
Vue.component('footer-bottomen', FooterComponenten)
Vue.component('crumbs-naven', CrumbsComponenten)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
