import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '@/pages/Home/index.vue'
import AboutPage from '@/pages/About/index.vue'
import PricePage from '@/pages/Price/index.vue'
import AccountNumberShow from '@/pages/AccountNumberShow/index.vue'
import HomePageen from '@/pages/Home/en.vue'
import AboutPageen from '@/pages/About/en.vue'
import PricePageen from '@/pages/Price/en.vue'
import AccountNumberShowen from '@/pages/AccountNumberShow/en.vue'

Vue.use(Router)

const createRouter = () => new Router({
  mode: "history",
  routes: [
   {
      path: '/',
      name: 'index',
      redirect: '/home_en'
   },
   {
      path: '/home',
      name: 'Home',
      component: HomePage
   },
   {
      path: '/about',
      name: 'About',
      component: AboutPage
   },
   {
      path: '/price',
      name: 'Price',
      component: PricePage
   },
   {
      path: '/accountNumberShow',
      name: 'AccountNumberShow',
      component: AccountNumberShow
   },
   {
      path: '/home_en',
      name: 'Home',
      component: HomePageen
   },
   {
      path: '/about_en',
      name: 'About',
      component: AboutPageen
   },
   {
      path: '/price_en',
      name: 'Price',
      component: PricePageen
   },
   {
      path: '/accountNumberShow_en',
      name: 'AccountNumberShow',
      component: AccountNumberShowen
   },
  ],
  scrollBehavior (to, from, savedPosition) {
   return { x: 0, y: 0 }  }
})

const router = createRouter()

export default router
