<template>
    <div>
       <div class="crumbs">
          <slot name="home"></slot>
          <div class="arrow"></div>
          <slot name="current"></slot>
       </div>
    </div>
 </template>
 
 <script>
 export default {
    name: "Crumbs"
 }
 </script>
 
 <style scoped>
 .crumbs {
    display: flex;
    margin-left: 260px;
    margin-top: 25px;
 }
 
 .arrow {
    width: 16px;
    height: 16px;
    background-image: url('../../assets/icon.png');
    background-repeat: no-repeat;
    background-size: 16px;
    margin: 7px;
 }
 </style>