<template>
    <div>
       <div class="swiper-container">
          <div class="header">
             <header-naven></header-naven>
          </div>
          <div class="swiper-wrapper">
             <div class="swiper-slide swiper-slide1">
                <img style="width: 100%;height: 100%;" src="../../assets/banner_en.png" alt="">
             </div>
             <div class="swiper-slide swiper-slide2">
               <img style="width: 100%;height: 100%;" src="../../assets/banner_en.png" alt="">
             </div>
             <div class="swiper-slide swiper-slide3">
               <img style="width: 100%;height: 100%;" src="../../assets/banner_en.png" alt="">
             </div>
             <div class="swiper-slide swiper-slide4">
               <img style="width: 100%;height: 100%;" src="../../assets/banner_en.png" alt="">
             </div>
          </div>
          <div class="swiper-button-prev rot" slot="button-prev">
             <img class="swiper-button-img" src="../../assets/left.png" />
          </div>
          <div class="swiper-button-next rat" slot="button-next">
             <img class="swiper-button-img" src="../../assets/right.png" />
          </div>
          <div class="swiper-pagination"></div>
       </div>
       <div class="box">
          <div class="box-detail" v-for="item in listOne" :key="item.id">
             <div class="img">
                <img :src="item.imgUrl" alt="">
             </div>
             <div class="title">
                <span>{{ item.title }}</span>
             </div>
             <!-- <div class="subheading">
                <span>{{ item.subheading }}</span>
             </div> -->
             <div class="detail">
                <span>{{ item.detail }}</span>
             </div>
          </div>
       </div>
       <div class="box1">
          <div class="box-detail" v-for="item in listTwo" :key="item.id">
             <div class="img">
                <img :src="item.imgUrl" alt="">
             </div>
             <div class="title">
                <span>{{ item.title }}</span>
             </div>
             <!-- <div class="subheading">
                <span>{{ item.subheading }}</span>
             </div> -->
             <div class="detail">
                <span>{{ item.detail }}</span>
             </div>
          </div>
       </div>
       <div>
          <CaseComponent></CaseComponent>
       </div>
       <div>
          <EnterpriseDynamicsComponent></EnterpriseDynamicsComponent>
       </div>
       <div>
          <ContactUs></ContactUs>
       </div>
       <div>
          <footer-bottomen></footer-bottomen>
       </div>
       <tool-baren></tool-baren>
    </div>
 </template>
 
 <script>
 import Swiper from 'swiper';
 import 'swiper/css/swiper.min.css'
 import CaseComponent from "@/components/Case/en.vue"
 import EnterpriseDynamicsComponent from "@/components/EnterpriseDynamics/en.vue"
 import ContactUs from "@/components/ContactUs/en.vue"
 import img1 from '@/assets/icon1.png'
 import img2 from '@/assets/icon2.png'
 import img3 from '@/assets/icon3.png'
 import img4 from '@/assets/icon4.png'
 import img5 from '@/assets/icon5.png'
 import img6 from '@/assets/icon6.png'
 
 export default {
    name: "Home",
    components: {
       CaseComponent,
       EnterpriseDynamicsComponent,
       ContactUs,
    },
    data() {
       return {
          listOne: [
             {
                id: " 001",
                imgUrl: img1,
                title: "The World's First Digital Media Distribution Platform",
                subheading: "Web3xGPT-AI—去中心化x人工智慧",
                detail: "With our top technical team, we have developed the world’s largest Tiktok matrix system, which currently spans 21 countries and regions and continues to expand."
             },
             {
                id: " 002",
                imgUrl: img2,
                title: "7+ Years of Industry Cultivation",
                subheading: "我們有卓越的行銷策劃團隊，豐富的產業資源。",
                detail: "Backed by a highly skilled operation team and extensive industry resources, we offer professional marketing services for your business through a powerful AI matrix system."
             },
             {
                id: " 003",
                imgUrl: img3,
                title: "Business Promotion Service",
                subheading: "AI數字技術領先性",
                detail: "Utilizing AIGC technology, we create digital influencers to promote your brand on Tiktok through AI matrix anchors."
             },
          ],
          listTwo: [
             {
                id: " 001",
                imgUrl: img4,
                title: "Ours Advantages",
                subheading: "全球範圍的上百個合作品牌",
                detail: "Having the world's largest matrix system, we have established over 100,000+ Tiktok accounts in 21 countries and regions. Partnering with Hong Kong Fengyun Group guarantees outstanding diversion results and high conversion rates."
             },
             {
                id: " 002",
                imgUrl: img5,
                title: "Market Prospect",
                subheading: "連續3年增長顯著,TikTok孕育勃勃新機",
                detail: "Global digital marketing spending reach to $700 billion in 2023, with AI serving as the foundation for future development. In the USA alone,Tiktok marketing services are expected to grow by 36% in 2024. With its continuously expanding user base, Tiktok has become the largest traffic pool in the world."
             },
             {
                id: " 003",
                imgUrl: img6,
                title: "Tiktok+AIGC",
                subheading: "将是人工智能市場的下一個風口",
                detail: "Tiktok has a huge user base around the world, providing tremendous potential business opportunities. The platform analyzes users’ behavior and interests through algorithms while using AI for more intelligent and precise advertising, which help your brand effectively promoting the information."
             },
          ]
       }
    },
    mounted() {
       new Swiper('.swiper-container', {
          autoplay: {
             delay: 3000,
          },
          loop: true,
          pagination: {
             el: '.swiper-pagination',
          },
          navigation: {
             nextEl: '.swiper-button-next',
             prevEl: '.swiper-button-prev',
          },
       })
    }
 
 }
 </script>
 
 <style scoped>
 .header {
    position: absolute;
    left: 0;
    z-index: 9999;
    width: 100%;
 }
 
 .swiper-container {
    height: 750px;
 }
 
 .swiper-button-prev:after,
 .swiper-container-rtl .swiper-button-next:after,
 .swiper-button-next:after,
 .swiper-container-rtl .swiper-button-prev:after {
    content: none;
 }
 
 .swiper-button-img {
    width: 45px;
    height: 45px;
 }
 
 .swiper-button-prev,
 .swiper-container-rtl .swiper-button-next {
    left: 58px;
    right: auto;
 }
 
 .swiper-button-next,
 .swiper-container-rtl .swiper-button-prev {
    right: 58px;
    left: auto;
 }
 
 .box {
    width: 1200px;
    margin: 80px auto 0;
    display: flex;
    justify-content: space-evenly;
 }
 
 .box1 {
    width: 1200px;
    margin: 30px auto 0;
    display: flex;
    justify-content: space-evenly;
 }
 
 
 .box-detail {
    width: 380px;
    height: 443px;
    background: #F6FAFF;
    border-radius: 24px;
 }
 
 .box-detail .img {
    width: 150px;
    height: 150px;
    border: 1px #888888 dashed;
    padding: 3px 10px 6px;
    box-sizing: border-box;
    margin: 31px auto 0;
 }
 
 .box-detail .img>img {
    width: 100%;
    height: 100%;
 }
 
 .box-detail .title {
    height: 33px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 33px;
    text-align: center;
    font-style: normal;
    margin: 26px auto 0;
 }
 
 .box-detail .subheading {
    height: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #777777;
    line-height: 22px;
    text-align: center;
    font-style: normal;
    margin: 10px auto 0;
 }
 
 .box .detail {
    width: 334px;
    height: 96px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #777777;
    line-height: 22px;
    text-align: center;
    font-style: normal;
    margin: 58px auto 0;
 }
 .box1 .detail {
    width: 334px;
    height: 96px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #777777;
    line-height: 22px;
    text-align: center;
    font-style: normal;
    margin-left: 23px;
    margin-top: 16px;
 }
 </style>