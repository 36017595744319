<template>
   <div class="bg">
      <div class="info">超強AI數字人孵化平臺，全托管運營讓您無後顧之憂</div>
      <div class="button">
         聯系我們
      </div>
   </div>
</template>

<script>
export default {
   name: "ContactUs",
   data() {
      return {}
   }
}
</script>

<style scoped>
.bg {
   width: 100%;
   height: 310px;
   background-image: url(../../assets/bg.png);
   background-repeat: no-repeat;
   background-size: 100% 310px;
   overflow: hidden;
}

.bg .info {
   height: 48px;
   font-family: PangMenZhengDao;
   font-size: 48px;
   color: #F3FDFF;
   line-height: 55px;
   text-shadow: 0px 4px 1px #0B6FF9;
   text-align: center;
   font-style: normal;
   margin-top: 98px
}

.bg .button {
   width: 150px;
   height: 40px;
   border-radius: 6px;
   border: 2px solid #FFFFFF;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 18px;
   color: #FFFFFF;
   line-height: 40px;
   text-align: center;
   font-style: normal;
   margin: 80px auto 0;
   cursor: pointer;
}
</style>