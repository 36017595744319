import { render, staticRenderFns } from "./en.vue?vue&type=template&id=6d795daa&scoped=true"
import script from "./en.vue?vue&type=script&lang=js"
export * from "./en.vue?vue&type=script&lang=js"
import style0 from "./en.vue?vue&type=style&index=0&id=6d795daa&prod&scoped=true&lang=css"
import style1 from "../../css/bootstrap.min.css?vue&type=style&index=1&id=6d795daa&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d795daa",
  null
  
)

export default component.exports