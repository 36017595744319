<template>
   <div class="header">
      <div class="logo">
         <img src="../../assets/logo.png" alt="">
      </div>
      <div class="nav">
         <div class="nav-wrapper">
            <div class="nav-item">
                <a  class="alink">
                    <router-link to="/home"> <span>首頁</span></router-link>
                </a>

            </div>
            <div class="nav-item">
                <a class="alink">
                  <router-link to="/price">業務訂購</router-link>
                </a>
            </div>
            <div class="nav-item">
                <a  class="alink">
                  <router-link to="/about">關於我們</router-link>
                </a>
            </div>
            <div class="nav-item">
                <a class="alink">
                  <router-link to="/kefu">聯系客服</router-link>
                </a>
            </div>
            <div class="nav-item">
                <a  class="alink">
                    <span>{{language}}</span>
                </a>
                <div class="nav-drop-down-wrapper">
                    <div class="nav-drop-down">
                        <!-- <div class="down-item">
                            <div class="down-item-wrapper">
                                <img src="../../assets/hongkong.png" alt="">
                                <span>中文（繁體）</span>
                            </div>
                        </div> -->
                        <div class="down-item">
                            <div @click="pushRouter" class="down-item-wrapper">
                                <img src="../../assets/usa.png" alt="">
                                <span>English</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "Header",
   data() {
      return {
         language:'Language'
      } 
   },
   methods: {
      pushRouter() {
        this.path = window.location.pathname + '_en'
        this.$router.push(this.path)
      },
      
   }
}
</script>

<style scoped>
.nav .nav-wrapper {
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
}
.nav .nav-wrapper .nav-item {
  color: #9e9e9e;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 800;
  position: relative;
  display: inline-block;
}
.nav .nav-wrapper .nav-item:hover {
  animation: jump 0.3s ease-in-out;
}
.nav .nav-wrapper .nav-item:hover .nav-drop-down-wrapper {
  display: block;
  opacity: 0;
  animation: show 0.4s ease-in-out;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}
.nav .nav-wrapper .nav-item .alink {
  border: 2px solid transparent;
  color: #9e9e9e;
  text-decoration: none;
  display: flex;
  flex-wrap: nowrap;
  padding: 10px 20px;
}
.nav .nav-wrapper .nav-item .alink img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.nav .nav-wrapper .nav-item .alink:hover {
  border-radius: 10px;
}
.nav .nav-wrapper .nav-item .nav-drop-down-wrapper {
  padding-top: 23px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}
.nav .nav-wrapper .nav-item .nav-drop-down-wrapper .nav-drop-down {
  min-width: 200px;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav .nav-wrapper .nav-item .nav-drop-down-wrapper .nav-drop-down .down-item {
  margin: 5px 0;
  box-sizing: border-box;
  width: 90%;
  border-radius: 10px;
}
.nav .nav-wrapper .nav-item .nav-drop-down-wrapper .nav-drop-down .down-item:hover {
  background-color: rgb(255, 229, 241);
  color: #727272;
}
.nav .nav-wrapper .nav-item .nav-drop-down-wrapper .nav-drop-down .down-item .down-item-wrapper {
  padding: 10px 0 10px 27px;
}
.nav .nav-wrapper .nav-item .nav-drop-down-wrapper .nav-drop-down .down-item .down-item-wrapper img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.nav .nav-wrapper .nav-item .nav-drop-down-wrapper .nav-drop-down .down-item .down-item-wrapper span {
  vertical-align: middle;
  margin-left: 5px;
}

@keyframes jump {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes show {
  0% {
    transform: translate(-50%, -5px);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0px);
    opacity: 1;
  }
}
a {
   text-decoration: none;
   padding: 0 5px;
   height: 25px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 18px;
   color: #76797f;
   line-height: 25px;
   text-align: center;
   font-style: normal;
}

.header {
   width: 100%;
   height: 68px;
   display: flex;
   justify-content: space-around;
   /* background-color: red; */
   border-bottom: 1px solid #cbd0de;
   align-items: center;
}

.header .logo {
   width: 153.9px;
   height: 54px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 36px;
   color: #000000;
   line-height: 68px;
   text-align: center;
   font-style: normal;
}

.header .logo>img{
   width: 100%;
   height: 100%;
}

.header .nav>ul {
   display: flex;
   line-height: 68px;
}

.header .nav>ul>li {
   list-style-type: none;
   margin: 0 20px;
}

.router-link-active {
   display: inline-block;
   height: 46px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 18px;
   color: #333333;
   text-align: center;
   font-style: normal;
   border-bottom: 3px solid;
}
</style>