<template>
    <div>
       <div class="header">
          <header-naven></header-naven>
       </div>
       <div class="main">
          <div class="crumbs-nav">
             <crumbs-nav>
                <template #home>
                   <div class="home">
                     Service Order
                   </div>
                </template>
                <template #current>
                   <div class="current">
                     Price
                   </div>
                </template>
             </crumbs-nav>
          </div>
          <div class="price-title">Price（價格）</div>
          <div class="table-cell">
             <table>
                <thead>
                   <tr>
                      <th style="width: 358px;">Country</th>
                      <th style="width: 380px;">Starting from 500 numbers</th>
                      <th style="width: 280px;">length of time</th>
                      <th style="width: 276px;">price</th>
                   </tr>
                </thead>
                <tbody>
                   <tr>
                      <td rowspan="4">
                         <div class="city">Turkey, Angola, Argentina, Italy, Mexico, Thailand, Vietnam, Brazil, Dubai, Japan, Korea, Indonesia, UK, Germany, France, Australia, Nigeria, Malaysia, Canada, Philippines, Spain
                         </div>
                         <div class="updata">Continuously updated······</div>
                      </td>
                      <td class="table-content">1000</td>
                      <td class="table-content">30s(30 sec)</td>
                      <td class="table-content">3$</td>
                   </tr>
                   <tr>
                      <td class="table-content">1000</td>
                      <td class="table-content">45s (45 sec)</td>
                      <td class="table-content">4$</td>
                   </tr>
                   <tr>
                      <td class="table-content">1000</td>
                      <td class="table-content">1min(1 min)</td>
                      <td class="table-content">5$</td>
                   </tr>
                   <tr>
                      <td class="table-content">1000</td>
                      <td class="table-content">1m30s(1 min 30 sec)</td>
                      <td class="table-content">6$</td>
                   </tr>
                   <tr>
                      <td colspan="4">
                         <div class="number">Please select the number of accounts you want to place:</div>
                         <div class="selected-price">
                            <div class="tag">
                               <div :class="['tag-price', item.id === selectedIndex ? 'tag-price-selected' : '']"
                                  v-for="item in priceList" :key="item.id" @click="selctedPriceHandler(item)">
                                  {{ item.price }}
                               </div>
                            </div>
                            <div class="price-input">
                               <div class="description">Customized Quantity：</div>
                               <div style="display: flex;">
                                  <div class="reduction-price" @click="less"></div>
                                  <div class="input-number"><input  maxlength="7" type="text" v-model="priceValue"></div>
                                  <div class="add-price" @click="add"></div>
                               </div>
                            </div>
                         </div>
                      </td>
                   </tr>
                   <tr>
                      <td colspan="4">
                         <div class="delivery-standard">Advertising Cycle Discount Criteria：</div>
                         <div class="standard-list">
                            <template v-for="item in standardList">
                               <div :class="['standard-list-item', item.className]" :key="item.id">
                                  <div style="text-align: center;margin-top: 32px;">
                                     <span class="item-day">{{ item.day }}</span>
                                     <span class="day-text">{{ item.dayText }}</span>
                                     <span class="segmentation">/</span>
                                     <span class="item-day">{{ item.discount }}</span>
                                     <span class="day-text">off</span>
                                  </div>
                                  <div class="order" @click="orderHandler">Order Now</div>
                               </div>
                            </template>
                         </div>
                      </td>
                   </tr>
                </tbody>
             </table>
          </div>
       </div>
       <div class="footer">
          <footer-bottomen></footer-bottomen>
       </div>
       <!-- <div v-if="isShowOrderFlag">
          <OrderDialog @closeOrderDalog="closeOrderDalog"></OrderDialog>
       </div> -->
       <tool-baren></tool-baren>
    </div>
 </template>
 
 <script>
 import OrderDialog from "@/components/OrderDialog/en.vue"
 export default {
    name: "Price",
    components:{
       OrderDialog
    },
    data() {
       return {
          isShowOrderFlag: false,
          selectedIndex: 3,
          priceValue: null,
          priceList: [
             {
                id: 1,
                price: 500
             },
             {
                id: 2,
                price: 1000
             },
             {
                id: 3,
                price: 1500
             },
             {
                id: 4,
                price: 2000
             },
          ],
          standardList: [
             {
                id: 1,
                day: "3",
                discount: "95%",
                dayText: "day",
                className: "black black1"
             }, {
                id: 2,
                day: "7",
                discount: "90%",
                dayText: "day",
                className: "black black2"
             }, {
                id: 3,
                day: "1",
                discount: "80%",
                dayText: "month",
                className: "white white1"
             }, {
                id: 4,
                day: "3",
                discount: "75%",
                dayText: "month",
                className: "white white2"
             }, {
                id: 5,
                day: "1",
                discount: "65%",
                dayText: "year",
                className: "white white3"
             }
          ]
       }
    },
    methods: {
       selctedPriceHandler(item) {
          this.selectedIndex = item.id
       },
       less() {
          if (!this.priceValue) return
          this.priceValue = Number(this.priceValue) - 1
       },
       add() {
          this.priceValue = Number(this.priceValue) + 1
       },
       orderHandler(){
          this.isShowOrderFlag = true
       },
       closeOrderDalog(){
          this.isShowOrderFlag = false
       }
    }
 }
 </script>
 
 <style scoped>
 .header {
    background-color: #ffff;
 }
 
 .main {
    width: 100%;
    background-image: url('../../assets/backdrop-bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    overflow: hidden;
    padding-bottom: 70px;
 }
 
 .home {
    width: 132px;
    height: 25px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #7d8084;
    line-height: 25px;
    text-align: center;
    font-style: normal;
 }
 
 .current {
    width: 36px;
    height: 25px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
    text-align: center;
    font-style: normal;
 }
 
 .price-title {
    height: 59px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 42px;
    color: #333333;
    line-height: 59px;
    text-align: center;
    font-style: normal;
 }
 
 .table-cell {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    border-radius: 6px;
 }
 
 table,
 th,
 td {
    border: 1px solid #f6f6f6;
    border-collapse: collapse;
 }
 
 th {
    height: 106px;
    background-color: #ECF3FE;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 26px;
    color: #333333;
    line-height: 37px;
    text-align: center;
    font-style: normal;
 }
 
 td {
    background-color: #fff;
 }
 
 .city {
    width: 317px;
    height: 168px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #666666;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    margin: 15px auto 0;
 }
 
 .updata {
    width: 276px;
    height: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 22px;
    color: #666666;
    line-height: 30px;
    text-align: left;
    font-style: normal;
    margin: 25px auto 10px;
 }
 
 .table-content {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 22px;
    color: #666666;
    line-height: 30px;
    text-align: center;
    font-style: normal;
 }
 
 .number {
    height: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 22px;
    color: #0B6FF9;
    line-height: 30px;
    text-align: center;
    font-style: normal;
    padding-top: 23px;
 }
 
 .selected-price {
    margin: 37px;
    display: flex;
    line-height: 38px;
    justify-content: space-between;
 }
 
 .tag {
    display: flex;
    justify-content: space-between;
 }
 
 .tag-price {
    width: 110px;
    height: 38px;
    border-radius: 8px;
    border: 1px solid #E8E8E8;
    text-align: center;
    line-height: 38px;
    margin: 0 10px;
    cursor: pointer;
 }
 
 .tag-price-selected {
    background: rgba(11, 111, 249, 0.2);
    border-radius: 8px;
    border: 1px solid #0B6FF9;
 }
 
 .price-input {
    display: flex;
 }
 
 .description {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #333333;
    text-align: left;
    font-style: normal;
 }
 
 .reduction-price {
    background-image: url('../../assets/less.png');
 }
 
 .add-price {
    background-image: url('../../assets/add.png');
 }
 
 .reduction-price,
 .add-price {
    width: 24px;
    height: 38px;
    background-repeat: no-repeat;
    background-position: 0 10px;
    margin: 0 10px;
    cursor: pointer;
 }
 
 .input-number>input {
    width: 110px;
    height: 38px;
    border-radius: 8px;
    border: 1px solid #8D8D8D;
    padding-left: 10px;
    box-sizing: border-box;
    font-size: 16px;
 }
 
 .input-number>input:focus {
    outline: none;
 }
 
 .delivery-standard {
    height: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 22px;
    color: #0B6FF9;
    line-height: 30px;
    text-align: center;
    font-style: normal;
    margin-top: 23px;
 }
 
 .standard-list {
    display: flex;
    justify-content: center;
    margin-top: 22px;
 }
 
 .standard-list-item {
    width: 239px;
    height: 160px;
    background: rgba(11, 111, 249, 0.5);
    border-radius: 0px 0px 12px 12px;
    box-sizing: border-box;
    margin: 0 2px;
 }
 
 .black,.white {}
 .black{
    color: #333;
 }
 
 .black1 {
    width: 238px;
    height: 160px;
    background: rgba(11, 111, 249, 0.1);
    border-radius: 0px 0px 12px 12px;
 }
 
 .black2 {
    width: 238px;
    height: 160px;
    background: rgba(11, 111, 249, 0.2);
    border-radius: 0px 0px 12px 12px;
 }
 
 
 .white {
    color: #ffff;
 }
 
 .white1 {
    width: 239px;
    height: 160px;
    background: rgba(11, 111, 249, 0.5);
    border-radius: 0px 0px 12px 12px;
 }
 
 .white2 {
    width: 239px;
    height: 160px;
    background: rgba(11, 111, 249, 0.8);
    border-radius: 0px 0px 12px 12px;
 }
 
 .white3 {
    width: 238px;
    height: 160px;
    background: #0B6FF9;
    border-radius: 0px 0px 12px 12px;
 }
 .item-day{
    width: 20px;
    height: 45px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 32px;
    line-height: 45px;
    text-align: left;
    font-style: normal;
 }
 .day-text{
    width: 14px;
    height: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    font-style: normal;
 }
 .segmentation{
    width: 7px;
    height: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    padding: 0 5px;
 }
 .order{
    width: 164px;
    height: 32px;
    background-image: url('../../assets/Button.png');
    background-repeat: no-repeat;
    background-size: 164px 32px;
    text-align: center;
    line-height: 32px;
    margin: 20px auto 0; 
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #0B6FF9;
    font-style: normal;
    cursor: pointer;
 }
 </style>