<template>
   <div class="bg">
      <div class="box">
         <div class="indicate">
            <div class="undertone" style="position: absolute;" ref="undertoneRef">
               <img src="../../assets/undertone.png" alt="">
            </div>
            <div class="list" v-for="(item, index) in list" :key="item.id" @click="menuHandler(item)">
               <div
                  :class="['menu-list-item-bg', 'menu-list-item', list.length - 1 == index ? 'menu-list-item-no-border' : '']">
                  <div :class="[indexFlag === item.id ? 'slected-title' : 'no-selected-title']">{{ item.title }}</div>
                  <div :class="[indexFlag === item.id ? 'slected-detail' : 'no-selected-detail']">{{ item.detail }}
                  </div>
               </div>
               <div :class="[index != 0 && 'list-border']"></div>
            </div>
         </div>
         <div class="floating-frame">
            <div ref="containerRef" class="container">
               <div v-for="item in list" :key="item.id">
                  <div class="floating-frame-img">
                     <img :src="item.imgUrl" alt="">
                  </div>
                  <div style="margin-top: 22px;display: flex;justify-content: space-between;">
                     <div class="selcted-menu-title">{{ item.title }}</div>
                     <div style="display: flex;justify-content: space-between;">
                        <div class="month">{{ item.month }}</div>
                        <div class="year">{{ item.year }}</div>
                     </div>
                  </div>
                  <div class="long-detail">
                     {{ item.longDetail }}
                  </div>
               </div>
            </div>
         </div>
         <div class="enterprise-dynamics">
            <div class="enterprise-dynamics-title">企業動態</div>
         </div>
      </div>
   </div>
</template>

<script>
import imgPath1 from "@/assets/123.png"
import imgPath2 from "@/assets/banner1.png"
import imgPath3 from "@/assets/banner2.png"
import imgPath4 from "@/assets/banner3.png"
import imgPath5 from "@/assets/banner4.png"
export default {
   name: "EnterpriseDynamics",
   data() {
      return {
         indexFlag: 1,
         selectedMenu: {
            id: 1,
            title: "TikTok APP全球下載量",
            detail: "TikTok APP全球下載量同比增長442%，...",
            longDetail: "TikTok APP全球下載量同比增長442%，2022年,TikTok廣告覆蓋約爲8.85 億人，廣告收入同比大幅增長200%，達到 110.4億美元(約合701億元人民币)(數據來源: Social Pilot)",
            imgUrl: imgPath1,
            year: "2023",
            month: "11·18"
         },
         list: [
            {
               id: 1,
               title: "TikTok APP全球下載量",
               detail: "TikTok APP全球下載量同比增長442%，...",
               imgUrl: imgPath1,
               year: "2023",
               month: "11·18",
               longDetail: "TikTok APP全球下載量同比增長442%，2022年,TikTok廣告覆蓋約爲8.85 億人，廣告收入同比大幅增長200%，達到 110.4億美元(約合701億元人民币)(數據來源: Social Pilot)",
            },
            {
               id: 2,
               title: "TikTok+數字人直播",
               detail: "AI 數字人正以各種職業身份滲透到各個行業...",
               imgUrl: imgPath2,
               year: "2023",
               month: "10·10",
               longDetail: "数字直播 面向直播场景,提供真人驱动和智能化驱动结合的方式和用户进行实时互动服务,为虚拟IP和企业品牌代言人提供商业化解决方案,助力企业直播间打造品牌影响力",
            },
            {
               id: 3,
               title: "TikTok優勢",
               detail: "與各垂直賽道媒體平台不同，TikTok擁有22...",
               imgUrl: imgPath3,
               year: "2022",
               month: "12·22",
               longDetail: "TikTok拥有庞大的用户基数和高频的用户活跃度,这为其电商业务提供了强大的流量支持。根据统计,TikTok的用户平均每天在平台上花费约82分钟,每月打开应用约100次。",
            },
            {
               id: 4,
               title: "公司開業",
               detail: "TikTok不僅緻力于保護用戶的隐私和數據安全...",
               imgUrl: imgPath4,
               year: "2023",
               month: "11·18",
               longDetail: "TikTok是抖音集团旗下的短视频社交平台。全球总部位于洛杉矶和新加坡,办公地点包括纽约、伦敦、都柏林、巴黎、柏林、迪拜、雅加达、首尔和东京。",
            }
         ]
      }
   },
   methods: {
      menuHandler(item) {
         this.indexFlag = item.id
         this.selectedMenu = item
         let undertoneRef = this.$refs.undertoneRef
         let containerRef = this.$refs.containerRef
         switch (item.id) {
            case 1:
               undertoneRef.style.transform = "translateY(0)"
               containerRef.style.transform = "translateY(0)"
               break;
            case 2:
               undertoneRef.style.transform = "translateY(96px)"
               containerRef.style.transform = "translateY(-460px)"
               break;
            case 3:
               undertoneRef.style.transform = "translateY(192px)"
               containerRef.style.transform = "translateY(-916px)"
               break;
            case 4:
               undertoneRef.style.transform = "translateY(288px)"
               containerRef.style.transform = "translateY(-1374px)"
               break;
            default:
               break;
         }
      }
   }
}
</script>

<style scoped>
.bg {
   width: 100%;
   height: 759px;
   background-image: url(../../assets/backdrop.png);
   overflow: hidden;
}

.bg .box {
   width: 1200px;
   height: 515px;
   background-color: #0B6FF9;
   border-radius: 14px;
   margin: 126px auto 0;
   position: relative;
}

.bg .box .indicate {
   width: 369px;
   height: 385px;
   background: rgba(255, 255, 255, 0.3);
   border-radius: 6px;
   position: absolute;
   top: 38px;
   left: 41px;
}

.floating-frame .container{
   transition: 0.3s all ease-in-out;
}

.undertone {
   transition: 0.3s all ease-in-out;
}

.bg .box .indicate .list {
   height: 25%;
   display: flex;
   transition: 0.3s all ease-in-out;
   cursor: pointer;
}

.bg .box .indicate .list .menu-list-item {
   width: 324px;
   margin: auto;
   padding-bottom: 18px;
   box-sizing: content-box;
   z-index: 999;
   padding-top: 15px;
}

.bg .box .indicate .list .menu-list-item-bg {
   /* background-image: url(../../assets/undertone.png); */
}

.bg .box .indicate .list .menu-list-item-no-border {
   border-bottom: none;
}

.bg .box .indicate .list .list-border {
   width: 333px;
   height: 2px;
   background: #FFFFFF;
   opacity: 0.5;
   position: absolute;
   left: 50%;
   transform: translate(-50%, -2px);
}

.bg .box .indicate .list .menu-list-item .slected-title {
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 24px;
   color: #0B6FF9;
   line-height: 33px;
   font-style: normal;
   white-space: nowrap;
}

.bg .box .indicate .list .menu-list-item .no-selected-title {
   height: 33px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 24px;
   color: #FFFFFF;
   line-height: 33px;
   font-style: normal;
   white-space: nowrap;
}

.bg .box .indicate .list .menu-list-item .slected-detail {
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 16px;
   color: #0B6FF9;
   line-height: 22px;
   font-style: normal;
   white-space: nowrap;
}

.bg .box .indicate .list .menu-list-item .no-selected-detail {
   height: 22px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 16px;
   color: #FFFFFF;
   line-height: 22px;
   font-style: normal;
   white-space: nowrap;
}

.border {
   width: 333px;
   height: 2px;
   background: #FFFFFF;
   opacity: 0.5;
   margin: 21px auto 0;
}

.bg .box .floating-frame {
   width: 692px;
   height: 465px;
   background: #FFFFFF;
   box-shadow: 0px -4px 20px 0px rgba(11, 111, 249, 0.28);
   border-radius: 30px;
   position: absolute;
   top: -42px;
   right: 36px;
   transition: 0.5s all ease-in-out;
   padding: 21px 22px;
   box-sizing: border-box;
   overflow: hidden;
}

.floating-frame-img {
   width: 648px;
   height: 305px;
}

.floating-frame-img>img {
   width: 100%;
   height: 100%;
}

.selcted-menu-title {
   width: 215px;
   height: 28px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 20px;
   color: #333333;
   line-height: 28px;
   font-style: normal;
}

.month {
   width: 47px;
   height: 28px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 20px;
   color: #333333;
   line-height: 28px;
   text-align: center;
   font-style: normal;
   margin-right: 16px
}

.year {
   width: 44px;
   height: 25px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 18px;
   color: #333333;
   line-height: 28px;
   text-align: center;
   font-style: normal;
}

.long-detail {
   width: 648px;
   height: 83px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 14px;
   color: #777777;
   line-height: 20px;
   text-align: left;
   font-style: normal;
   margin-top: 22px;
}

.enterprise-dynamics {
   position: absolute;
   width: 368px;
   height: 76px;
   left: 50%;
   bottom: 0;
   transform: translate(-50%, 50%);
   text-align: center;
   background-image: url(../../assets/Button.png);
   background-repeat: no-repeat;
   background-size: cover;
}

.enterprise-dynamics>.enterprise-dynamics-title {
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 28px;
   line-height: 76px;
   color: #0B6FF9;
   text-align: center;
   font-style: normal;
}
</style>