<template>
   <div>
      <div class="mask"></div>
      <div class="dialog-box">
         <div class="header">
            <div class="header-title">支付说明：</div>
            <div class="close" @click="closeHandler"></div>
         </div>
         <div class="detail">完成支付后请点击客服发送业务需求！</div>
         <div class="main">
            <div @click="orderHandler(item)" :class="['box', orderFlag === item.id ? 'active-order' : '']"
               v-for="item in orderList" :key="item.id">
               <div class="sale">SALE</div>
               <div class="timeout">
                  <span class="delay">时长：</span>
                  <span class="number">{{ item.time }}</span>
                  <span class="s">{{ item.unit }}</span>
               </div>
               <div class="money">
                  <div class="currency">
                     <span class="flag">$</span>
                     <span class="number">{{ item.price }}</span>
                  </div>
                  <div class="discount">
                     <span class="number">{{ item.fold }}</span>
                     <span class="fold">折</span>
                  </div>
               </div>
               <div :class="['order-button', orderFlag === item.id ? 'active-order-button' : '']">
                  立即订购
               </div>
            </div>
            <div class="pay-code-box">
               <div class="pay-code">
                  <div class="two-dimensional-code">
                     <img style="width: 114px;height: 114px;" src="../../assets/erweima.png" alt="">
                  </div>
                  <div class="content-area">
                     <div class="business">已选择时长：30s的业务</div>
                     <div>
                        <span class="flag">$</span>
                        <span class="price">4275.0</span>
                     </div>
                     <div class="payment-method">请选择支付方式扫码支付</div>
                     <div class="payment-service">《支付服务须知》</div>
                  </div>

               </div>
               <div class="post-request">完成支付后请点击客服发送业务需求！</div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "OrderDialog",
   data() {
      return {
         orderFlag: 1,
         orderList: [
            {
               id: 1,
               time: "30",
               price: "3.0",
               fold: "9.5",
               unit: "s"
            },
            {
               id: 2,
               time: "45",
               price: "4.0",
               fold: "9.5",
               unit: "s"
            },
            {
               id: 3,
               time: "1",
               price: "5.0",
               fold: "9.5",
               unit: "m"
            },
            {
               id: 4,
               time: "1.5",
               price: "6.0",
               fold: "9.5",
               unit: "m"
            },
         ]
      }
   },
   methods: {
      closeHandler() {
         this.$emit('closeOrderDalog')
      },
      orderHandler(item) {
         this.orderFlag = item.id
      }
   }
}
</script>

<style scoped>
.mask {
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background: rgba(0, 0, 0, 0.5);
}

.dialog-box {
   width: 732px;
   /* height: 617px; */
   background: #ffffff;
   position: absolute;
   top: 388px;
   left: 50%;
   transform: translateX(-50%);
   padding: 28px 36px;
   box-sizing: border-box;
}

.header {
   display: flex;
   justify-content: space-between;

}

.header .header-title {
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 22px;
   color: #777777;
   line-height: 30px;
   text-align: center;
   font-style: normal;
}

.header .close {
   width: 22px;
   height: 22px;
   background-image: url('../../assets/Off.png');
   background-repeat: no-repeat;
   background-size: 22px;
   cursor: pointer;
}

.detail {
   width: 306px;
   height: 25px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 18px;
   color: #333333;
   line-height: 25px;
   text-align: center;
   font-style: normal;
   padding-top: 20px;
   margin-bottom: 20px;
}

.main {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: space-between;
}

.main .box {
   width: 320px;
   height: 160px;
   background: #FFFFFF;
   border-radius: 12px;
   border: 1px solid #E2E2E2;
   margin-top: 20px;
   cursor: pointer;
   position: relative;
}

.box .sale {
   width: 55px;
   height: 26px;
   background-image: url('../../assets/Corner.png');
   background-repeat: no-repeat;
   background-size: 55px 26px;
   position: absolute;
   top: -13px;
   left: 0;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 14px;
   color: #FFFFFF;
   line-height: 26px;
   text-align: center;
   font-style: normal;
}

.box .timeout,
.money {
   margin-top: 23px;
   text-align: center;
}

.timeout .delay {
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 24px;
   color: #333333;
   line-height: 33px;
   text-align: left;
   font-style: normal;
}

.timeout .number {
   width: 29px;
   height: 33px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 24px;
   color: #333333;
   line-height: 33px;
   text-align: left;
   font-style: normal;
}

.timeout .s {
   width: 10px;
   height: 20px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 14px;
   color: #333333;
   line-height: 20px;
   text-align: left;
   font-style: normal;
}

.money {
   margin-top: 0;
   display: flex;
   justify-content: center;
}

.currency .flag {
   width: 14px;
   height: 30px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 22px;
   color: #0B6FF9;
   line-height: 30px;
   text-align: left;
   font-style: normal;
   vertical-align: super;
}

.currency .number {
   width: 53px;
   height: 50px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 36px;
   color: #0B6FF9;
   line-height: 50px;
   text-align: left;
   font-style: normal;
   padding-left: 7px;
}

.discount {
   margin-left: 30px;
   margin-top: 16px;
}

.discount .number {
   width: 30px;
   height: 28px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 20px;
   color: #949494;
   line-height: 28px;
   text-align: left;
   font-style: normal;
}

.discount .fold {
   width: 12px;
   height: 17px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 12px;
   color: #949494;
   line-height: 17px;
   text-align: left;
   font-style: normal;
   padding-left: 6px;
}

.main .order-button {
   width: 164px;
   height: 32px;
   border-radius: 38px;
   border: 1px solid #E2E2E2;
   margin: 6px auto 0;
   text-align: center;
   line-height: 32px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 14px;
   color: #999999;
   text-align: center;
   font-style: normal;
}

.main .active-order {
   background: rgba(11, 111, 249, 0.1);
   border: 1px solid #0B6FF9;
}

.main .active-order-button {
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 14px;
   color: #0B6FF9;
   text-align: center;
   font-style: normal;
   border: none;
   background-image: url('../../assets/Button.png');
   background-repeat: no-repeat;
   background-size: 164px 32px;
}

.pay-code-box {
   width: 660px;
   height: 267px;
   border-radius: 12px;
   border: 1px solid #E2E2E2;
   margin: 25px auto 0;
}

.pay-code-box .pay-code {

   display: flex;
}

.pay-code .two-dimensional-code {
   width: 148px;
   height: 148px;
   border: 1px solid #E2E2E2;
   margin: 43px 0 0 148px;
   box-sizing: border-box;
   padding: 17px;
}

.pay-code .content-area {
   margin: 48px 0 0 24px;
}

.pay-code .business {
   width: 193px;
   height: 25px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 18px;
   color: #333333;
   line-height: 25px;
   text-align: left;
   font-style: normal;
}

.pay-code .flag {
   width: 14px;
   height: 30px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 22px;
   color: #F44E57;
   line-height: 30px;
   text-align: left;
   font-style: normal;
   vertical-align: super;
}

.pay-code .price {
   width: 117px;
   height: 50px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 36px;
   color: #F44E57;
   line-height: 50px;
   text-align: left;
   font-style: normal;
   padding-left: 7px;
}

.pay-code .payment-method {
   width: 176px;
   height: 22px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 16px;
   color: #777777;
   line-height: 22px;
   text-align: left;
   font-style: normal;
}

.pay-code .payment-service {
   width: 112px;
   height: 20px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 14px;
   color: #999999;
   line-height: 20px;
   text-align: left;
   font-style: normal;
   margin-top: 4px;
}

.post-request {
   height: 22px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 500;
   font-size: 16px;
   color: #777777;
   line-height: 22px;
   text-align: center;
   font-style: normal;
   margin-top: 21px;
}
</style>