<template>
    <div>
        <div class="header">
            <header-naven></header-naven>
        </div>
        <div class="main">
            <div>
                <img style="width: 100%;height: 400px;" src="../../assets/abouten.png" alt="">
            </div>
            <div class="about-area pt-90">
                <div class="container">
                    <div class="area-title center-title">
                        <div class="aboutUs">關于我們</div>
                        <h2 class="aboutTitle">About Us</h2>
                        <div class="content">
                            <div class="text left animate__animated animate__backInLeft">
                                <p>
                                    &emsp;&emsp;Hong Kong Fengyun Group is a technology company dedicated to leveraging AI digital scientific advancements. With the mission of "make life digital and bring digital to life" and continuous upgrades of AI technology, we have achieved exceptional global business promotion performance on Tiktok and ultimately ensure your brand's success in the digital era..
                                </p>
                                <p>
                                    &emsp;&emsp;Let's collaborate and embrace the digital future together. Hong Kong Fengyun Group will continue the relentless innovation across various AI application, empowering your business with more vitality, and jointly creating a successful digital journey. with you.
                                </p>
                            </div>
                            <div class="img right animate__animated animate__backInRight">
                                <img src="../../assets/about2.png" />
                                <div class="imgbro"></div>
                            </div>
                        </div>
                    </div>
                    <div style="display: flex;">
                    <div style="margin: 100px 0 100px 0;" class="coldse">
                        <div class="featuresBox">
                            <div class="featuresBox-img">
                                <img src="../../assets/aboutFutuer3.png" alt="">
                            </div>
                            <div class="featuresBox-title">
                                <span>Our Vision</span>
                            </div>
                            <div class="featuresBox-text">
                                <span>help global brands succeed in the digital era</span>
                            </div>
                        </div>
                    </div>
                    <div style="margin: 100px 30px 100px 30px;" class="coldse">
                        <div class="featuresBox">
                            <div class="featuresBox-img">
                                <img src="../../assets/aboutFutuer2.png" alt="">
                            </div>
                            <div class="featuresBox-title">
                                <span>Our Mission</span>
                            </div>
                            <div class="featuresBox-text">
                                <span>make life digital and bring digital to life</span>
                            </div>
                        </div>
                    </div>
                    <div style="margin: 100px 0 100px 0;" class="coldse">
                        <div class="featuresBox">
                            <div class="featuresBox-img">
                                <img src="../../assets/aboutFutuer1.png" alt="">
                            </div>
                            <div class="featuresBox-title">
                                <span>Corporate Culture Values</span>
                            </div>
                            <div class="featuresBox-text">
                                <span>Innovation-oriented and digital-driven</span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <footer-bottom></footer-bottom>
        </div>
        <tool-baren></tool-baren>
    </div>
</template>

<script>
import OrderDialog from "@/components/OrderDialog/index.vue"
export default {
    name: "Price",
    components: {
        OrderDialog
    },
    data() {
        return {}
    },
    methods: {

    }
}
</script>
<style scoped>
@import '../../css/about.css';
@import '../../css/font-awesome.min.css';
@import '../../css/ionicons.min.css';
@import '../../css/animate.css';
@import '../../css/magnific-popup.css';
@import '../../css/meanmenu.css';
@import '../../css/global.css';
@import '../../css/style.css';
@import '../../css/animate.min.css';

.header {
    background-color: #ffff;
}
.featuresBox {
    position: relative;
    width: 380px;
    height: 184px;
    background: #F6FAFF;
    border-radius: 24px;
}

.featuresBox-img {
    position: absolute;
    height: 90px;
    bottom: 124px;
    left: 143px;
}

.featuresBox-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 33px;
    text-align: center;
    font-style: normal;
    padding-top: 90px;
}

.featuresBox-text {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #777777;
    line-height: 22px;
    text-align: center;
    font-style: normal;
    margin-top: 20px;
   
}
</style>
<style scoped src="../../css/bootstrap.min.css"></style>