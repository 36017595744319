<template>
   <div class="bg">
      <div class="box">
         <div class="indicate">
            <div class="undertone" style="position: absolute;" ref="undertoneRef">
               <img src="../../assets/undertone.png" alt="">
            </div>
            <div class="list" v-for="(item, index) in list" :key="item.id" @click="menuHandler(item)">
               <div
                  :class="['menu-list-item-bg', 'menu-list-item', list.length - 1 == index ? 'menu-list-item-no-border' : '']">
                  <div :class="[indexFlag === item.id ? 'slected-title' : 'no-selected-title']">{{ item.title }}</div>
                  <div :class="[indexFlag === item.id ? 'slected-detail' : 'no-selected-detail']">{{ item.detail }}
                  </div>
               </div>
               <div :class="[index != 0 && 'list-border']"></div>
            </div>
         </div>
         <div class="floating-frame">
            <div ref="containerRef" class="container">
               <div v-for="item in list" :key="item.id">
                  <div class="floating-frame-img">
                     <img :src="item.imgUrl" alt="">
                  </div>
                  <div style="margin-top: 22px;display: flex;justify-content: space-between;">
                     <div class="selcted-menu-title">{{ item.title }}</div>
                     <div style="display: flex;justify-content: space-between;">
                        <div class="month">{{ item.month }}</div>
                        <div class="year">{{ item.year }}</div>
                     </div>
                  </div>
                  <div class="long-detail">
                     {{ item.longDetail }}
                  </div>
               </div>
            </div>
         </div>
         <div class="enterprise-dynamics">
            <div class="enterprise-dynamics-title">Enterprise dynamics</div>
         </div>
      </div>
   </div>
</template>

<script>
import imgPath1 from "@/assets/123.png"
import imgPath2 from "@/assets/banner1.png"
import imgPath3 from "@/assets/banner2.png"
import imgPath4 from "@/assets/banner3.png"

export default {
   name: "EnterpriseDynamics",
   data() {
      return {
         indexFlag: 1,
         selectedMenu: {
            id: 1,
            title: "TikTok APP Global Downloads",
            detail: "TikTok APP global downloads up 442% year-on-year...",
            longDetail: "TikTok APP global downloads grew 442% year-on-year, and in 2022, TikTok's ad coverage will reach 885 million people, and ad revenue will grow 200% year-on-year, reaching $11.04 billion (about RMB 70.1 billion) (Source: Social Pilot).",
            imgUrl: imgPath1,
            year: "2023",
            month: "11·18"
         },
         list: [
            {
               id: 1,
               title: "TikTok APP Global Downloads",
               detail: "TikTok APP global downloads up 442% year-on-year...",
               longDetail: "TikTok APP global downloads grew 442% year-on-year, and in 2022, TikTok's ad coverage will reach 885 million people, and ad revenue will grow 200% year-on-year, reaching $11.04 billion (about RMB 70.1 billion) (Source: Social Pilot).",
               imgUrl: imgPath1,
               year: "2023",
               month: "11·18"
            },
            {
               id: 2,
               title: "TikTok+Digital live streaming",
               detail: "AI digital people are infiltrating various...",
               imgUrl: imgPath2,
               year: "2023",
               month: "10·10",
               longDetail: "Digital Live For live broadcasting scenarios, provide real-time interactive services with users in a combination of reality-driven and intelligent-driven ways, provide commercialization solutions for virtual IP and corporate brand spokespersons, and help enterprises build brand influence......",
            },
            {
               id: 3,
               title: "TikTok advantage",
               detail: "Unlike the various vertical track media...",
               imgUrl: imgPath3,
               year: "2022",
               month: "12·22",
               longDetail: "TikTok has a large user base and high-frequency user activity, which provides strong traffic support for its e-commerce business. According to statistics, TikTok users spend an average of about 82 minutes a day on the platform, and open the application about 100 times a month.",
            },
            {
               id: 4,
               title: "Establishment of a company",
               detail: "TikTok is not only committed to protecting...",
               imgUrl: imgPath4,
               year: "2023",
               month: "11·18",
               longDetail: "TikTok is a short-form social video platform owned by the Jieyin Group. Global headquarters are located in Los Angeles and Singapore, with offices in New York, London, Dublin, Paris, Berlin, Dubai, Jakarta, Seoul and Tokyo.",
            }
         ]
      }
   },
   methods: {
      menuHandler(item) {
         this.indexFlag = item.id
         this.selectedMenu = item
         let undertoneRef = this.$refs.undertoneRef
         let containerRef = this.$refs.containerRef
         switch (item.id) {
            case 1:
               undertoneRef.style.transform = "translateY(0)"
               containerRef.style.transform = "translateY(0)"
               break;
            case 2:
               undertoneRef.style.transform = "translateY(96px)"
               containerRef.style.transform = "translateY(-460px)"
               break;
            case 3:
               undertoneRef.style.transform = "translateY(192px)"
               containerRef.style.transform = "translateY(-916px)"
               break;
            case 4:
               undertoneRef.style.transform = "translateY(288px)"
               containerRef.style.transform = "translateY(-1374px)"
               break;
            default:
               break;
         }
      }
   }
}
</script>

<style scoped>
.bg {
   width: 100%;
   height: 759px;
   background-image: url(../../assets/backdrop.png);
   overflow: hidden;
}

.bg .box {
   width: 1200px;
   height: 515px;
   background-color: #0B6FF9;
   border-radius: 14px;
   margin: 126px auto 0;
   position: relative;
}

.bg .box .indicate {
   width: 369px;
   height: 385px;
   background: rgba(255, 255, 255, 0.3);
   border-radius: 6px;
   position: absolute;
   top: 38px;
   left: 41px;
}

.floating-frame .container {
   transition: 0.3s all ease-in-out;
}

.undertone {
   transition: 0.3s all ease-in-out;
}

.bg .box .indicate .list {
   height: 25%;
   display: flex;
   transition: 0.3s all ease-in-out;
   cursor: pointer;
}

.bg .box .indicate .list .menu-list-item {
   width: 324px;
   margin: auto;
   padding-bottom: 18px;
   box-sizing: content-box;
   z-index: 999;
   padding-top: 15px;
}

.bg .box .indicate .list .menu-list-item-bg {
   /* background-image: url(../../assets/undertone.png); */
}

.bg .box .indicate .list .menu-list-item-no-border {
   border-bottom: none;
}

.bg .box .indicate .list .list-border {
   width: 333px;
   height: 2px;
   background: #FFFFFF;
   opacity: 0.5;
   position: absolute;
   left: 50%;
   transform: translate(-50%, -2px);
}

.bg .box .indicate .list .menu-list-item .slected-title {
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 24px;
   color: #0B6FF9;
   line-height: 33px;
   font-style: normal;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.bg .box .indicate .list .menu-list-item .no-selected-title {
   height: 33px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 24px;
   color: #FFFFFF;
   line-height: 33px;
   font-style: normal;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.bg .box .indicate .list .menu-list-item .slected-detail {
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 16px;
   color: #0B6FF9;
   line-height: 22px;
   font-style: normal;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.bg .box .indicate .list .menu-list-item .no-selected-detail {
   height: 22px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 16px;
   color: #FFFFFF;
   line-height: 22px;
   font-style: normal;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.border {
   width: 333px;
   height: 2px;
   background: #FFFFFF;
   opacity: 0.5;
   margin: 21px auto 0;
}

.bg .box .floating-frame {
   width: 692px;
   height: 465px;
   background: #FFFFFF;
   box-shadow: 0px -4px 20px 0px rgba(11, 111, 249, 0.28);
   border-radius: 30px;
   position: absolute;
   top: -42px;
   right: 36px;
   transition: 0.5s all ease-in-out;
   padding: 21px 22px;
   box-sizing: border-box;
   overflow: hidden;
}

.floating-frame-img {
   width: 648px;
   height: 305px;
}

.floating-frame-img>img {
   width: 100%;
   height: 100%;
}

.selcted-menu-title {
   width: 315px;
   height: 28px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 20px;
   color: #333333;
   line-height: 28px;
   font-style: normal;
}

.month {
   width: 47px;
   height: 28px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 20px;
   color: #333333;
   line-height: 28px;
   text-align: center;
   font-style: normal;
   margin-right: 16px
}

.year {
   width: 44px;
   height: 25px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 18px;
   color: #333333;
   line-height: 28px;
   text-align: center;
   font-style: normal;
}

.long-detail {
   width: 648px;
   height: 83px;
   font-family: PingFangSC, PingFang SC;
   font-weight: 400;
   font-size: 14px;
   color: #777777;
   line-height: 20px;
   text-align: left;
   font-style: normal;
   margin-top: 22px;
   
}

.enterprise-dynamics {
   position: absolute;
   width: 368px;
   height: 76px;
   left: 50%;
   bottom: 0;
   transform: translate(-50%, 50%);
   text-align: center;
   background-image: url(../../assets/Button.png);
   background-repeat: no-repeat;
   background-size: cover;
}

.enterprise-dynamics>.enterprise-dynamics-title {
   font-family: PingFangSC, PingFang SC;
   font-weight: 600;
   font-size: 28px;
   line-height: 76px;
   color: #0B6FF9;
   text-align: center;
   font-style: normal;
}
</style>